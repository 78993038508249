import React, { lazy, Suspense } from "react";
// import LandingSection from "./Sections/LandingSection";
// import FeaturesSection from "./Sections/FeaturesSection";
// import ServicesSection from "./Sections/ServicesSection";
// import PlansSection from "./Sections/PlansSection";
// import TestimonialsSection from "./Sections/TestimonialsSection";
// import BusinessPackagesSection from "./Sections/BusinessPackagesSection";
import styled from "styled-components";
import { CircleLoader } from "react-spinners";

export const SectionTitle = styled.h2`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 28px;
  text-transform: capitalize;
  background: var(--appColor-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
`;

export const SectionSubtitle = styled.h3`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
  color: var(--appColor-dark);
`;

const LandingSection = lazy(() => import("./Sections/LandingSection"));
const FeaturesSection = lazy(() => import("./Sections/FeaturesSection"));
const BusinessPackagesSection = lazy(
  () => import("./Sections/BusinessPackagesSection")
);
const ServicesSection = lazy(() => import("./Sections/ServicesSection"));
const PlansSection = lazy(() => import("./Sections/PlansSection"));
const TestimonialsSection = lazy(
  () => import("./Sections/TestimonialsSection")
);

const Homepage: React.FC<{}> = () => {
  return (
    <div id="homepage" className="w-full">
      <Suspense
        fallback={
          <div className="w-full flex flex-col justify-center items-center py-72">
            <CircleLoader color="var(--appColor-main)" />
          </div>
        }
      >
        <LandingSection />
        <FeaturesSection />
        <BusinessPackagesSection />
        <ServicesSection />
        <PlansSection />
        <TestimonialsSection />
      </Suspense>
    </div>
  );
};

export default Homepage;
