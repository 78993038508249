import { CURRENCY_TYPES } from "./currencyActionTypes";

const initialState = {isKsh: true};

const messageReducer = (state: any = initialState, action: any) => {
  const { type } = action;

  switch (type) {
    case CURRENCY_TYPES.SET_KSH:
      return { isKsh: true };

    case CURRENCY_TYPES.SET_USD:
      return { isKsh: false };

    default:
      return state;
  }
}

export default messageReducer;
