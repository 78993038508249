import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const MainAppLayout: React.FC<{}> = () => {
  return (
    <div className="w-full relative">
      <div className="w-full fixed z-30">
        <Navbar />
      </div>

      <div className="largeScreenLayout">
        <section className="w-full largeScreenSize z-10">
          <Outlet />
        </section>
      </div>
      
      <Footer />
    </div>
  );
};

export default MainAppLayout;
