import { NOTIFICATION_TYPES } from "./notificationActionTypes";

const initialState = { isLoading: false, notificationError: false };

const notificationReducer = (state: any = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case NOTIFICATION_TYPES.SET_NOTIFICATION_LOADING:
      return { ...state, isLoading: payload };

    case NOTIFICATION_TYPES.SEND_NOTIFICATION_SUCCESS:
      return { ...state, notificationError: false };

    case NOTIFICATION_TYPES.SEND_NOTIFICATION_FAIL:
      return { ...state, notificationError: true };

    case NOTIFICATION_TYPES.CLEAR_NOTIFICATION_ERROR:
      return { ...state, notificationError: false };

    default:
      return state;
  }
};

export default notificationReducer;
