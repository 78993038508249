import TwitterIcon from "../assets/social_media_icons/twitter_icon.svg";
import InstagramIcon from "../assets/social_media_icons/instagram_icon.svg";
import FacebookIcon from "../assets/social_media_icons/facebook_icon.svg";
// import LinkedinIcon from "../assets/social_media_icons/linkedin_icon.svg";
// import YoutubeIcon from "../assets/social_media_icons/youtube_icon.svg";
// import MailIcon from "../assets/icons/mail.svg";
// import PhoneIcon from "../assets/icons/phone.svg";

export interface IContactsProps {
  contact: string;
  value: string;
  action: string;
  icon: string;
}

export interface ISocialMediaProps {
  platform: string;
  link: string;
  brandIcon?: any;
}

export const contacts_data: Array<IContactsProps> = [
  {
    contact: "email",
    value: "info@hostbyt.com",
    action: "mailto",
    icon: "fontisto:email",
  },
  // {
  //   contact: "phone",
  //   value: "+254770528179",
  //   action:"tel",
  //   icon: "akar-icons:phone",
  // },
];

export const social_media_data: Array<ISocialMediaProps> = [
  {
    platform: "twitter",
    link: "https://twitter.com/HostByt",
    brandIcon: TwitterIcon,
  },
  {
    platform: "instagram",
    link: "https://www.instagram.com/hostbyt/",
    brandIcon: InstagramIcon,
  },
  {
    platform: "facebook",
    link: "https://www.facebook.com/HostByt-Web-Services-103833258712234",
    brandIcon: FacebookIcon,
  },
  // {
  //   platform: "linkedin",
  //   link: "https://www.linkedin.com/company/hostbyt-web-services/",
  //   brandIcon: LinkedinIcon,
  // },
  // {
  //   platform: "youtube",
  //   link: "https://www.youtube.com/",
  //   brandIcon: YoutubeIcon,
  // },
];
