export interface IPolicyProps {
  paragraph: string;
}

export interface IParagraphProps {
  paragraph: string;
}

export interface ISubtitlesProps {
  subtitle?: string;
  subSection: {
    paragraphs: IParagraphProps[];
  };
}

export interface ISectionProps {
  title: string;
  subsection: {
    subtitles: ISubtitlesProps[];
  };
}

export const terms_of_service_intro: Array<IPolicyProps> = [
  {
    paragraph:
      'These Terms of Service (the "Agreement") are an agreement between HostByt.com ("HostByt", "us", "our", or the “Company”) and you (“Client” or “Customer” or "User" or "you" or "your"). This Agreement sets forth the general terms and conditions of your use of the products and services made available by HostByt and of the HostByt.com website (collectively, the "Services"). By using the Services, you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Services.',
  },
  {
    paragraph:
      "HostByt may modify, add, or delete portions of this Agreement at any time. If we have made significant changes to this Agreement, we will post a notice on the HostByt.com website for at least thirty (30) days after the changes are posted and will indicate at the bottom of this Agreement the date of the last revision. Any revisions to this Agreement will become effective when posted unless otherwise provided. You agree to any modification to this Agreement by continuing to use the Services after the effective date of any such modification.",
  },
];

export const terms_of_service: Array<ISectionProps> = [
  {
    title: "1.\u2003Additional Policies and Agreements",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "Use of the Services means you also agree to the terms of the following policies:",
              },
              {
                paragraph: "•\u2002Acceptable Use Policy",
              },
              {
                paragraph: "•\u2002Privacy Policy",
              },
            ],
          },
        },
      ],
    },
  },
  {
    title: "2.\u2003Account Eligibility",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "By registering for or using the Services, you represent and warrant that you are eighteen (18) years of age or older. The Services are intended solely for Users who are eighteen (18) years of age or older. Any registration, use of or access to the Services, by anyone under eighteen (18) is unauthorized and is a violation of this Agreement.",
              },
              {
                paragraph:
                  "If you use the Services on behalf of another party you agree that you are authorized to bind such other party to this Agreement and to act on such other party's behalf with respect to any actions you take in connection with the Services.",
              },
              {
                paragraph:
                  "It is your responsibility to provide accurate, current, and complete information on the registration forms. If there is ever an abuse issue or we need to contact you, we will use the email address we have on file. If you need to change your email address, you may open a ticket and request to update your email address.",
              },
              {
                paragraph:
                  "You agree to be fully responsible for all use of your account and for any actions that take place through your account. It is your responsibility to maintain the confidentiality of your password and other information related to the security of your account.",
              },
            ],
          },
        },
      ],
    },
  },
  {
    title: "3.\u2003Billing and Payment Information",
    subsection: {
      subtitles: [
        {
          subtitle: "3.1\u2003Prices",
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "Clients agree that HostByt’s prices are subject to the prevailing market dynamics and such the initial registration and/or renewal prices can increase or decrease based on new dynamics in the market. In the event that this happens, the new prices will be indicated on the relevant pages on the website of HostByt.com.",
              },
            ],
          },
        },
        {
          subtitle: "3.2\u2003Auto Renewal",
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "Unless otherwise provided, you agree that until and unless you notify HostByt of your desire to cancel the Services, you will be billed on an automatically recurring basis to prevent any disruption to your Services, using the billing information on file with us.",
              },
            ],
          },
        },
        {
          subtitle: "3.3\u2003Late Payment",
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "All invoices must be paid within five(5) days of the invoice due date. Any invoice that is outstanding for more than five(5) days may result in the suspension or termination of Services. Access to the account will not be restored until payment has been received. If you fail to pay the fees as specified herein, HostByt may suspend or terminate your account and pursue the collection costs incurred by HostByt, including without limitation, any arbitration and legal fees, and reasonable attorneys' fees. HostByt will not activate new orders or activate new packages for Customers who have an outstanding balance on their account.",
              },
            ],
          },
        },
        {
          subtitle: "3.4\u2003Refunds",
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "We do offer a refund if a written request (by opening a ticket) is submitted to the support team within five(5) days of the purchasing date.",
              },
              {
                paragraph:
                  "Only first-time accounts are eligible for a refund. For example, if you've had an account with us before, canceled and signed up again, or if you have opened a second account with us, you will not be eligible for a refund. Violations of this Agreement will waive your rights under the refund policy.",
              },
              {
                paragraph:
                  "If a hosting service had a free domain name given as part of the package, the amount that would have been incurred to register the domain had it been registered separately, will be deducted when doing the refund. That is to mean that the refund will be minus the cost of the domain registration.",
              },
              {
                paragraph:
                  "We do not offer refunds for purchases of domain names.",
              },
            ],
          },
        },
      ],
    },
  },
  {
    title: "4.\u2003User Content",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  'You may be able to upload, store, publish, display and distribute information, text, photos, videos, emails, and other content on or through the Services (collectively, "User Content"). User Content includes any content posted by you or by users of any of your websites hosted through the Services ("User Websites"). You are solely responsible for any and all User Content and any transactions or other activities conducted on or through User Websites. By posting or distributing User Content on or through the Services, you represent and warrant to HostByt that (i) you have all the necessary rights to post or distribute such User Content, and (ii) your posting or distribution of such User Content does not infringe or violate the rights of any third party.',
              },
              {
                paragraph:
                  "HostByt exercises no control over, and accepts no responsibility for, User Content or the content of any information passing through HostByt's computers, network hubs and points of presence or the Internet. HostByt does not monitor User Content. However, you acknowledge and agree that HostByt may, but is not obligated to, immediately take any corrective action in HostByt's sole discretion, including without limitation removal of all or a portion of the User Content or User Websites, and suspend or terminate any and all Services without refund if you violate the terms of this Agreement. You hereby agree that HostByt shall have no liability due to any corrective action that HostByt may take.",
              },
              {
                paragraph:
                  "We try to maintain frequent backups for all our Client’s files. It is the  Client’s sole  responsibility to make backups and save them elsewhere off the server. Availability of server backups  is not guaranteed and we are not responsible for loss of Client data. Customers are advised to make backups of the information they store on our servers on a regular basis. Our cPanel accounts provide tools that allow Clients to make data backups, download data, download backups etc.",
              },
              {
                paragraph:
                  "It is your responsibility to ensure that scripts/programs installed under your account are secure and permissions of directories are set properly, regardless of the installation method. When at all possible, set permissions on most directories to 755 or as restrictive as possible. Users are ultimately responsible for all actions taken under their account. This includes the compromise of credentials such as username and password. You are required to use a secure password.",
              },
            ],
          },
        },
      ],
    },
  },
  {
    title: "5.\u2003Domain Name Registration",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "HostByt offers domain name registration and renewal services in partnership with ICANN accredited registrars. HostByt processes registration and renewal services through these respective registrars at its discretion. Promotional offers, including  special introductory rates, promotional rates, or any non-regular pricing, are only available to new  Customers and are only valid for the initial term. All domain name registrations will renew at the regular renewal rate. Domain names which have opted in to the Automatic Domain Renewal service, and any optional features associated with them, will be automatically renewed for the minimum term available for that domain type at the regular renewal rate six(6) days prior to expiry. You will be solely responsible for all renewal costs and for any other costs in doing so. Members may choose to opt in or out of the Automatic Domain Renewal service via the Client Area.",
              },
              {
                paragraph:
                  "If your hosting plan includes a free domain name, HostByt may automatically renew your domain name for you upon expiry as part of your hosting plan’s benefits, regardless of your Automatic Domain Renewal settings. You acknowledge and agree that you may not transfer the domain name registration to another domain name registrar during the first sixty(60) days of the initial registration, or within sixty(60) days of any successful transfer.  HostByt is authorized to deny any such transfer requests. You acknowledge and agree that HostByt may, but is not obligated to, place your domain name in a Domain Lock status to prevent unauthorized or hostile transfers of your domain name.",
              },
              {
                paragraph:
                  "Domain transfer services are included only for active domain names that are either registered or renewed through HostByt at the regular rate. Clients may transfer expired domain names and domain names that were purchased at a promotional rate to another domain name registrar by paying a Domain Transfer Fee. The Domain Transfer Fee is based on the domain type and is subject to any applicable taxes.",
              },
            ],
          },
        },
      ],
    },
  },
  {
    title:
      "6.\u2003Abuse Reports/Prohibited Conduct/Acceptable Use Policy (AUP)",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "Any account which causes us to receive an abuse report may be terminated and/or have access to services suspended. If you do not remove malicious content from your account after being notified by HostByt of an issue, we reserve the right to leave access to services disabled.",
              },
              {
                paragraph:
                  "Any account found connecting to a third party network or system without authorization from the third party is subject to suspension. Access to networks or systems outside of your direct control requires the express written consent of the third party. HostByt may, at our discretion, request documentation to prove that your access to a third party network or system is authorized.",
              },
              {
                paragraph:
                  "For more details on the Acceptable Use Policy, see Acceptable Use Policy.",
              },
            ],
          },
        },
      ],
    },
  },
  {
    title: '7.\u2003HIPAA Disclaimer. We are NOT "HIPAA compliant."',
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "You are solely responsible for any applicable compliance with federal or state laws governing the privacy and security of personal data, including medical or other sensitive data. You acknowledge that the Services may not be appropriate for the storage or control of access to sensitive data, such as information about children or medical or health information. HostByt does not control or monitor the information or data you store on, or transmit through, the Services.",
              },
              {
                paragraph:
                  'We specifically disclaim any representation or warranty that the Services, as offered, comply with the federal Health Insurance Portability and Accountability Act ("HIPAA"). Customers requiring secure storage of "protected health information" as defined under HIPAA are expressly prohibited from using the Services for such purposes. Storing and permitting access to "protected health information" is a material violation of this Agreement, and grounds for immediate account termination. We do not sign "Business Associate Agreements" and you agree that HostByt is not a Business Associate or subcontractor or agent of yours pursuant to HIPAA.',
              },
            ],
          },
        },
      ],
    },
  },
  {
    title: "8.\u2003Reservation of Rights",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph: "HostByt reserves the right and sole discretion to:",
              },
              {
                paragraph:
                  "•\u2002Censor any website hosted on its servers that is deemed inappropriate.",
              },
              {
                paragraph:
                  "•\u2002Review any account for excessive space or bandwidth utilization and  to suspend service to those accounts that have exceeded allowed levels.",
              },
              {
                paragraph:
                  "•\u2002Terminate any account for non-payment of fees, for providing fraudulent account information or fraudulent payment information.",
              },
              {
                paragraph:
                  "•\u2002Terminate any account if the contents of its website results in, or are the subject of, legal action or threatened legal action against HostByt or any of its affiliates or partners, without consideration for whether such legal action or threatened legal action is eventually determined to be with or without merit.",
              },
              {
                paragraph:
                  "•\u2002Terminate any account for unsolicited, commercial e-mailing (i.e. Spam), illegal access to other computers or networks (i.e. hacking), distribution of Internet viruses or similar destructive activities, activities whether lawful or unlawful that HostByt determines to be harmful to its other customers, operations or reputation, or for any breach of this agreement.",
              },
              {
                paragraph:
                  "•\u2002Suspend the Service at any time for any duration of time when necessary, without penalty or liability to ourselves.",
              },
              {
                paragraph:
                  "You agree that it may be necessary for us to temporarily suspend the Service for technical reasons or to maintain network equipment or facilities.",
              },
            ],
          },
        },
      ],
    },
  },
  {
    title: "9.\u2003Customer Abuse",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "We have zero tolerance for abusive language and/or abusive behavior towards our Company and/or service we provide and/or our employees. Any  Customer deemed at our sole discretion to be abusive to our Company and/or service we provide and/or our  employees will result in immediate irrevocable account termination without any refund.",
              },
              {
                paragraph:
                  "Furthermore, posting negative feedback on any public forum about our  company and/or our employees before submitting a ticket to our “Support Ticket” or “Support Email” department to discuss your issue will result in immediate account termination without any refund. Although it is your  right to post feedback without  discussing your issue with us first, it is undoubtedly unfair and unprofessional not to discuss your issue with us first, which is not the type of Client we want to deal with.",
              },
              {
                paragraph:
                  "We have the utmost respect for all of our Clients, and are more than fair with all of our  Clients. We will do anything and everything possible to keep all of our Clients satisfied. But in the unlikely event you are not satisfied, all that we ask is to discuss your issue with our support department first.",
              },
            ],
          },
        },
      ],
    },
  },
  {
    title: "10.\u2003Indemnification",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "Client agrees that they will protect, indemnify, save and hold HostByt harmless from any and all stipulations, liabilities, losses, expenses and claims, including but not limited to reasonable attorney’s fees assessed against HostByt, its agents, customers, officers, employees, officers, shareholders, directors and administration that may arise or result from any service provided or performed or agreed to be performed or any product sold by its customers, agents, employees, officers, shareholders, directors, or assigns. Client agrees to defend, indemnify and hold HostByt harmless against liabilities arising out of:",
              },
              {
                paragraph:
                  "(1) any  injury to person or property caused by any products sold or distributed in association with HostByt’s Services;",
              },
              {
                paragraph:
                  "(2) any material furnished by customer infringing or allegedly infringing  on the proprietary rights of a third party;",
              },
              {
                paragraph:
                  "(3) copyright, trademark patent, or other intellectual property violation and any defective products sold to a customer from HostByt’s Services.",
              },
            ],
          },
        },
      ],
    },
  },
  {
    title: "11.\u2003Disclaimer",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "HostByt shall not be responsible for any damages your business may suffer. HostByt makes no warranties of any kind, expressed or implied for the Services. HostByt disclaims any warranty of merchantability or fitness for a particular purpose, including loss of data resulting from delays, delivery failures, wrong deliveries, and any and all service interruptions caused by HostByt or our employees.",
              },
              {
                paragraph:
                  "HostByt reserves the right to modify, change, or discontinue any aspect of the Services at any time.",
              },
            ],
          },
        },
      ],
    },
  },
];
