import { IPolicyProps, ISectionProps } from "./terms_of_service";

export const privacy_policy_intro: Array<IPolicyProps> = [
  {
    paragraph:
      "Your privacy is very important to us, below explains the information we collect from you and what we do with that information. This Privacy Policy regulates the processing of personal data on behalf of the Customer by HostByt and is incorporated by reference into HostByt's Terms of Service, in which the parties have agreed the terms for HostByt's delivery of the Services to the Customer.",
  },
  {
    paragraph:
      "We reserve the right to add, modify, delete or change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. Your continued use of the HostByt website and/or the Services constitutes your agreement to this Privacy Policy, as amended from time to time.",
  },
  {
    paragraph:
      "Our site may contain links which may lead you to other sites which may have their own privacy policy over which we have no control. Please understand that once you leave our site, our privacy policy will no longer apply.",
  },
  {
    paragraph:
      "The transmission of information via the internet, email or text message is not completely secure. Although we will do our best to protect your personal information, we cannot guarantee the security of your information transmitted through the Services or over email; any transmission is at your own risk. Once we have received your information, we will take appropriate technical and organizational measures to safeguard your personal information against loss, theft and unauthorized use, access or modification.",
  },
  {
    paragraph:
      "Where we have given you (or where you have chosen) a password which enables you to access certain parts of our website, you are responsible for keeping the password confidential. We ask you not to share a password with anyone.",
  },
];

export const privacy_policy: Array<ISectionProps> = [
  {
    title: "1.\u2003Information Collected",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "To enable you to create an account and place an order on our website, we need to have the following basic information about you: Your Names (First Name and Last Name), E-mail Address, Phone Number, Address, Postcode, City, State/Region/County, Country, etc.",
              },
              {
                paragraph:
                  "Apart from this, our systems gather certain details about your computer’s internet connection like your IP address when you visit our site. Your IP address does not identify you personally. We use this information to deliver our web pages to you upon request, to customize our site as per your interest and to keep a log of where the Services are being accessed from.",
              },
              {
                paragraph:
                  "We do not allow any unauthorized personnel or organizations; be it other members, visitors, or anyone not a part of our company to use any information collected from you.",
              },
            ],
          },
        },
      ],
    },
  },
  {
    title: "2.\u2003Information Sharing",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "We do not rent, sell, barter, or give away your information to anyone. Information may be shared with law authorities, for fraud detection, and for the safety of our site, employees, management, users, members and other parties associated with us.",
              },
            ],
          },
        },
      ],
    },
  },
  {
    title: "3.\u2003Legal Disclaimer",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "We reserve the right to disclose your personally identifiable information as required by law and when we believe that disclosure is necessary to protect our rights and/or to comply with a judicial proceeding, court order, or legal process served on our website.",
              },
            ],
          },
        },
      ],
    },
  },
];
