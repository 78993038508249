export interface IAboutUsProps {
  paragraph: string;
}

export const about_us_data: Array<IAboutUsProps> = [
  // {
  //   paragraph:
  //     "HostByt is a leading web hosting service provider based in Kenya. Our goal is to make website developers' and their customers' lives easier. We also aim to enable individuals to fully utilize the internet and thrive with the help of our web hosting services. We accomplish this by providing web hosting services that are simple to use, quick, and dependable. ",
  // },
  {
    paragraph:
      "Welcome to Hostbyt Web Services, your number one source for all things web development, web design and web hosting. We're dedicated to giving you the very best of our services, with a focus on customer satisfaction, small businesses growth, state of the art web hosting services, basically anything you need to establish your digital presence for your business.",
  },
  {
    paragraph:
      "Hostbyt Web Services has come a long way from its beginnings. When we first started out, our passion for helping others have an online presence drove us to create and host websites for businesses at an affordable rate so that Hostbyt Web Services can offer you the best services for most businesses' websites and hosting allowing them to grow their businesses and personal brands. We now serve customers all over the world and are thrilled that we're able to help most businesses have their reach increased by having an online presence."
  },
  {
    paragraph:
      "We hope you enjoy all our services as much as we enjoy offering them to you.",
  },
];
