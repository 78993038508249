import React from "react";
import { PolicyHeadings, PolicySubHeadings, SubHeadingTopics } from ".";
import {
  aup_intro,
  aup,
} from "../../data/acceptable_use_policy";
import { scroll } from ".";

const AUP: React.FC<{}> = () => {
  return (
    <div className="w-full md:px-4 px-9 pt-24 flex flex-row justify-between textColorDark">
      <div className="md:hidden w-1/4">
        <div className="sticky top-20">
          <SubHeadingTopics className="my-2">Acceptable Use Policy</SubHeadingTopics>
          {aup.map((item: any, index: number) => (
            <p
              className="my-1 cursor-pointer"
              key={index}
              onClick={() => scroll(item.title)}
            >
              {item.title}
            </p>
          ))}
        </div>
      </div>

      <div className="md:w-full w-3/4 md-min:ml-10 pb-20">
        <PolicyHeadings>Acceptable Use Policy</PolicyHeadings>
        <div className="pt-5">
          {aup_intro.map((item: any, index: number) => (
            <p className="my-5" key={index}>
              {item.paragraph}
            </p>
          ))}
        </div>

        {aup.map((item: any, index: number) => (
          <div className="wfull pt-5" key={index}>
            {/* Policy Heading/Title */}
            <div className="w-full">
              <PolicySubHeadings id={item.title}>
                {item.title}
              </PolicySubHeadings>
            </div>

            {item.subsection.subtitles.map((item: any, index: number) => (
              <>
                {/* Policy Sub Heading */}
                <SubHeadingTopics className="my-5" key={index}>
                  {item.subtitle}
                </SubHeadingTopics>
                <>
                  {item.subSection.paragraphs.map(
                    (item: any, index: number) => (
                      // Paragraphs
                      <p className="my-5" key={index}>
                        {item.paragraph}
                      </p>
                    )
                  )}
                </>
              </>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AUP;
