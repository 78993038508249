interface INavbarProps {
  link: string;
  to: string;
}

export const navbar_links: Array<INavbarProps> = [
  {
    link: "Home",
    to: "/",
  },
  {
    link: "Domains",
    to: "/",
    // to: "https://hostbyt.com/billing/cart.php?a=add&domain=register",
  },
  {
    link: "Hosting",
    to: "/",
    // to: "https://hostbyt.com/billing/index.php?rp=/store/shared-hosting",
  },
  {
    link: "Knowledgebase",
    to: "/",
    // to: "https://hostbyt.com/billing/index.php?rp=/knowledgebase",
  },
  {
    link: "Contact Us",
    to: "/",
    // to: "https://hostbyt.com/billing/submitticket.php?step=2&deptid=1",
  },
];
