import React, { PropsWithChildren, useState } from "react";
import PropTypes from "prop-types";
import "./navbar.css";
import { navbar_links } from "../../../data/navbar_links";
import Button from "../Button";
import Logo from "../../../assets/Logo.png";
import { Icon } from "@iconify/react";
import { ReactComponent as CartIcon } from "../../../assets/icons/cart.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import Dropdown from "../Dropdown";
import { setKsh, setUSD } from "../../../store/Currency/currencyActionCreator";
import { connect, useDispatch } from "react-redux";
// import PromoBanner from "../PromoBanner";

interface IProps {
  isKsh?: boolean;
}

const Navbar: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { isKsh } = props;

  // var width = window.innerWidth;
  // const isMobileWidth = width <= 800 ? true : false;

  const dispatch = useDispatch();

  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const handleCurrencyChange = (
    e: React.ChangeEvent<HTMLOptionElement>
  ): void => {
    if (e.currentTarget.value === "Ksh") {
      dispatch(setKsh());
    } else if (e.currentTarget.value === "USD") {
      dispatch(setUSD());
    }
  };

  const toCart = () => {
    // let path = `https://hostbyt.com/billing/cart.php?a=view`;
    let path = `/`;
    window.location.href = path;
  };

  const toClientArea = () => {
    // let path = `https://hostbyt.com/billing/clientarea.php`;
    let path = `/`;
    window.location.href = path;
  };

  function toggleSideNavItems(value: boolean) {
    setIsSideNavOpen(value);
  }

  const toggleOpen = isSideNavOpen ? "open" : "";
  const toggleColor = isSideNavOpen ? "appBgColorDark" : "";
  const toggleAnimation = isSideNavOpen ? "openNav" : "";

  return (
    <>
      <nav className="w-full absolute top-0 z-30 appBgColorLight">
        {/* Offer banner */}
        {/* <PromoBanner /> */}

        {/* Large screen navbar */}
        <div className="w-full largeScreenLayout">
          <div className="lg:hidden nav_footerLargeScreenSize w-full px-8 py-5 flex flex-row justify-between">
            <a href="/" className="my-auto">
              <img
                src={Logo}
                alt="Logo"
                className="lg:h-7 h-10 pointer-events-none"
              />
            </a>

            <div className="my-auto">
              {navbar_links.map((item: any, index: number) => (
                <a
                  href={item.to}
                  className="lg:mx-0 mx-2 px-2 py-2 textColorDark"
                  key={index}
                >
                  {item.link}
                </a>
              ))}
            </div>

            <div className="w-auto flex flex-row justify-end items-center">
              <div className="">
                <Dropdown
                  id="currency"
                  name="currency"
                  className="textColorDark"
                  option="rounded"
                  onChange={handleCurrencyChange}
                >
                  <option value="Ksh" selected={isKsh}>
                    Ksh
                  </option>
                  <option value="USD" selected={!isKsh}>
                    USD
                  </option>
                </Dropdown>
              </div>

              <Button
                id="cartBtn"
                className="mx-3"
                primary
                backgroundColor="transparent"
                hasIcon
                iconBtn
                icon={<CartIcon />}
                option="circle"
                onClick={toCart}
              />

              <Button
                id="signupBtn"
                className="appBgGradient"
                primary
                color="var(--appColor-light)"
                label="Client Area"
                option="rounded"
                onClick={toClientArea}
              />
            </div>
          </div>
        </div>

        {/* mobile navbar */}
        <div
          className={[
            "w-full px-5 py-5 lg-min:hidden flex flex-row justify-between",
            toggleColor,
          ].join(" ")}
        >
          <div className="my-auto">
            <div
              id="menuBurgerIcon"
              className={["cursor-pointer", toggleOpen].join(" ")}
              onClick={() => toggleSideNavItems(!isSideNavOpen)}
            >
              <div id="menuBurger"></div>
            </div>
          </div>

          <a href="/" className="my-auto">
            <img src={Logo} alt="Logo" className="h-7" />
          </a>

          <div className="my-auto">
            <Button
              id="cartBtn"
              primary
              backgroundColor="transparent"
              hasIcon
              iconBtn
              icon={
                <Icon
                  icon="bx:bxs-cart"
                  className=""
                  color="var(--appColor-main)"
                  fontSize={25}
                />
              }
              option="circle"
              onClick={toCart}
            />

            <Button
              id="signupBtn"
              primary
              backgroundColor="transparent"
              option="circle"
              hasIcon
              iconBtn
              icon={<UserIcon />}
              onClick={toClientArea}
            />
          </div>
        </div>
      </nav>

      {isSideNavOpen && (
        <div className="absolute z-10">
          <section
            className="h-screen w-screen bg-gray-500 fixed top-0 opacity-50"
            onClick={() => toggleSideNavItems(!isSideNavOpen)}
          ></section>
        </div>
      )}

      <div
        id="mobileNav"
        className={[
          "w-4/5 h-screen absolute z-20 appBgColorDark",
          toggleAnimation,
        ].join(" ")}
      >
        <div className="pt-20 textColorLight">
          {navbar_links.map((item, index) => (
            <a href={item.to} className="block w-auto p-3 mt-1" key={index}>
              {item.link}
            </a>
          ))}
        </div>

        <div className="w-1/2 ml-3 my-5">
          <Dropdown
            id="currency"
            name="currency"
            className="textColorLight"
            option="rounded"
            onChange={handleCurrencyChange}
          >
            <option value="Ksh" selected={isKsh}>
              Ksh
            </option>
            <option value="USD" selected={!isKsh}>
              USD
            </option>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

Navbar.propTypes = {
  children: PropTypes.any,
};

function mapStateToProps(state: any) {
  return {
    isKsh: state.currency.isKsh,
  };
}

export default connect(mapStateToProps)(Navbar);
