import React from "react";
import { Link } from "react-router-dom";

const PageNotFound: React.FC<{}> = () => {
  return (
    <div className="w-full h-screen appMainBg textColorDark">
      <div className="w-full h-full flex flex-col justify-center items-center">
        <p className="my-5 font-urbanist font-medium text-8xl">404</p>
        <p className="text-5xl font-urbanist text-center font-medium">Page Not Found</p>
        <span className="my-5 flex flex-row capitalize">
          <Link className="underline font-urbanist" to="/">
            Click Here
          </Link>
          &nbsp;
          <p className="font-urbanist"> to go back to the home page</p>
        </span>
      </div>
    </div>
  );
};

export default PageNotFound;
