import MpesaLogo from "../assets/payment_methods_logos/mpesa_logo.png";
import PaypalLogo from "../assets/payment_methods_logos/paypal_logo.png";
import MastercardLogo from "../assets/payment_methods_logos/mastercard_logo.png";
import VisaLogo from "../assets/payment_methods_logos/visa_logo.png";

export interface IFooterLinksProps {
  linkName: string;
  to: string;
}

export interface IPaymentOptionsProps {
  logo: any;
}

export const footer_links_data: Array<IFooterLinksProps> = [
  {
    linkName: "About Us",
    to: "/about-us",
  },
  {
    linkName: "Acceptable Use Policy",
    to: "/acceptable-use-policy",
  },
  {
    linkName: "Announcements",
    to: "/",
  },
  {
    linkName: "Knowledgebase",
    to: "/",
  },
  {
    linkName: "Privacy Policy",
    to: "/privacy-policy",
  },
  {
    linkName: "Support",
    to: "/",
  },
  {
    linkName: "Terms of Service",
    to: "/terms-of-service",
  },
];

export const payment_options_data: Array<IPaymentOptionsProps> = [
  {
    logo: MpesaLogo,
  },
  {
    logo: PaypalLogo,
  },
  {
    logo: MastercardLogo,
  },
  {
    logo: VisaLogo,
  },
];
