import { MISCELLANEOUS_TYPES } from "./miscellaneousActionTypes";

const initialState = { isQuoteModalOpen: false, prefilledMessage: "" };

const messageReducer = (state: any = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case MISCELLANEOUS_TYPES.OPEN_QUOTE_MODAL:
      return { ...state, isQuoteModalOpen: true };

    case MISCELLANEOUS_TYPES.CLOSE_QUOTE_MODAL:
      return { ...state, isQuoteModalOpen: false };

    case MISCELLANEOUS_TYPES.PREFILL_MODAL:
      return { ...state, prefilledMessage: payload };

    case MISCELLANEOUS_TYPES.CLEAR_PREFILLED_MODAL:
      return { ...state, prefilledMessage: "" };

    default:
      return state;
  }
};

export default messageReducer;
