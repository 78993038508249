import { IPolicyProps, ISectionProps } from "./terms_of_service";

export const aup_intro: Array<IPolicyProps> = [
  {
    paragraph:
      'This Acceptable Use Policy ("AUP") governs your use of the Services and is incorporated by reference into HostByt\'s Terms of Service. Unless otherwise stated, defined terms in this AUP have the same meaning as provided in the Terms of Service. HostByt may modify this AUP at any time without notice.',
  },
  {
    paragraph:
      "The Services provided by HostByt may only be used for lawful purposes. You agree to comply with all applicable laws, rules and regulations in connection with your use of the Services. HostByt reserves the right to refuse service to anyone at our sole discretion. Any material or conduct that in our judgment violates this AUP in any manner may result in suspension or termination of the Services or removal of content with or without notice.",
  },
  {
    paragraph:
      "Failure to respond to a communication from our abuse/support department within the specified time period in our communication to you may result in the suspension or termination of the Services.",
  },
  {
    paragraph:
      "Your use of the Services must be in compliance with Kenyan laws, and the laws of your country at all times.",
  },
  {
    paragraph:
      "You are responsible for ensuring that your use of the Service does not consume excessive system or network resources that disrupts the normal use of the Services through, but not limited to, spawning multiple processes, or consuming excessive amounts of memory, CPU or bandwidth capacity.",
  },
];

export const aup: Array<ISectionProps> = [
  {
    title: "1.\u2003Prohibited Uses",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "\u2003•\u2002You may not use the Services to send spam or bulk unsolicited messages. We may terminate with or without notice the account of any user who sends spam.",
              },
              {
                paragraph:
                  "\u2003•\u2002You may not use the Services to publish content or engage in activity that is illegal under applicable law, that is harmful to others, or that would subject HostByt to liability.",
              },
              {
                paragraph:
                  "\u2003•\u2002You may not use the Services to distribute computer viruses, worms, Trojan horses or other malicious code.",
              },
              {
                paragraph:
                  "\u2003•\u2002You may not use the Services to host scripts or processes that adversely impact our systems.",
              },
              {
                paragraph:
                  "\u2003•\u2002You may not use the Services to host or distribute pornographic content.",
              },
              {
                paragraph:
                  "\u2003•\u2002You may not host or distribute content linking to child sexual abuse material (CSAM) or any child pornography or content that is harmful to minors (CSAM will be suspended immediately without notice and reported to law enforcement).",
              },
              {
                paragraph:
                  "\u2003•\u2002You may not use the Services to engage in phishing or engaging in identity theft.",
              },
              {
                paragraph:
                  "\u2003•\u2002You may not use the Services to host ponzi or pyramid schemes, or websites that engage in unlawful or deceptive marketing practices.",
              },
              {
                paragraph:
                  "\u2003•\u2002You may not use the Services to access another network without permission, including to probe or scan for vulnerabilities or breach security or authentication measures.",
              },
              {
                paragraph:
                  "\u2003•\u2002You may not use the Services to attack other networks (e.g., utilizing botnets or other means to launch Denial of Service (DoS) or other attacks).",
              },
              {
                paragraph:
                  "\u2003•\u2002You may not use the Services to intercept or monitor data.",
              },
              {
                paragraph:
                  "\u2003•\u2002You may not use the Services to gain access to any network or system using any deep-link, page-scrape, robot, crawl, index, spider, offline reader, click spam, macro programs, internet agent, or other automatic device, program, algorithm or methodology, to use, access, copy, index, acquire information, generate impressions or clicks, input information, store information, search, generate searches, or monitor any portion of HostByt’s website or servers for any unauthorized purpose.",
              },
              {
                paragraph:
                  "\u2003•\u2002You may not use the Services in reverse engineering, reverse  compiling or otherwise deriving the underlying source code or structure or sequence of any HostByt, solution or technology.",
              },
              {
                paragraph:
                  "\u2003•\u2002You may not use your hosting account as a backup solution. Our Services are designed to host your website only and may not be used as a data repository. HostByt reserves the right to remove backups from your hosting account with or without notice.",
              },
            ],
          },
        },
      ],
    },
  },
  {
    title: "2.\u2003Prohibited Contents",
    subsection: {
      subtitles: [
        {
          subSection: {
            paragraphs: [
              {
                paragraph:
                  "The following is a non-exclusive list of content that is prohibited by HostByt:",
              },
              {
                paragraph:
                  "•\u2003Content that has or contains links to nudity, pornography, adult  content, materials with sex or foul language.",
              },
              {
                paragraph:
                  "•\u2003Content that condones, promotes, contains, or links to warez, cracks,  hacks, their associated utilities, or other piracy related information, whether for educational purposes or not.",
              },
              {
                paragraph:
                  "•\u2003Content that is grossly offensive to the community, including blatant expressions for bigotry, prejudice, racism, hatred or excessive profanity, or to post any obscene, filthy, excessively violent, harassing, or otherwise objectionable material.",
              },
              {
                paragraph:
                  "•\u2003Content that promotes or facilitates violence or terrorist activities.",
              },
              {
                paragraph:
                  "•\u2003Content that sells or promotes any products or services that are unlawful in the location at which the content is posted or received.",
              },
              {
                paragraph:
                  "•\u2003Content that infringes or violates any copyright, patent, trademark, service mark, trade name, trade secret, or other intellectual property  right of any third party.",
              },
              {
                paragraph:
                  "•\u2003Content that posts or discloses any personally identifying information or private information about any third parties without their express consent.",
              },
            ],
          },
        },
      ],
    },
  },
];
