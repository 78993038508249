// import { Navigate } from "react-router-dom";
import {
  HomePage,
  AUP,
  PrivacyPolicy,
  TermsOfService,
  PageNotFound,
  AboutUs,
} from "../views/index";
import MainAppLayout from "../shared/layouts/MainAppLayout";

const routes = () => [
  {
    path: "/",
    element: <MainAppLayout />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "acceptable-use-policy", element: <AUP /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "terms-of-service", element: <TermsOfService /> },
      { path: "about-us", element: <AboutUs /> },
    ],
  },
  { path: "*", element: <PageNotFound /> },
];

export default routes;
