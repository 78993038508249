import { MSG_TYPES } from "./messageActionTypes";

const initialState = {};

const messageReducer = (state: any = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case MSG_TYPES.SET_MESSAGE:
      return { message: payload };

    case MSG_TYPES.CLEAR_MESSAGE:
      return { message: "" };

    default:
      return state;
  }
}

export default messageReducer;
