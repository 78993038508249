import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
// import auth from "./User/userReducer";
import message from "./API_ResponseMessage/messageReducer";
import currency from "./Currency/currencyReducer";
import notifications from "./Notifications/notificationReducer";
import miscellaneous from "./Miscellaneous/miscellaneousReducer";

const middleware = [thunk];

const appReducer = combineReducers({
  message,
  currency,
  notifications,
  miscellaneous,
});

// const rootReducer = (state: any, action: any) => {
//   // when a logout action is dispatched it will reset redux state
//   if (action.type === "LOGOUT") {
//     state = undefined;
//   }

//   return appReducer(state, action);
// };

const store = createStore(
  appReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
