import { MISCELLANEOUS_TYPES } from "./miscellaneousActionTypes";

export const openQuoteModal = () => ({
  type: MISCELLANEOUS_TYPES.OPEN_QUOTE_MODAL,
});

export const closeQuoteModal = () => ({
  type: MISCELLANEOUS_TYPES.CLOSE_QUOTE_MODAL,
});

export const prefillModal = (data: string) => (dispatch: any) => {
  dispatch({
    type: MISCELLANEOUS_TYPES.PREFILL_MODAL,
    payload: data,
  });
};

export const clearPrefilledModal = () => ({
  type: MISCELLANEOUS_TYPES.CLEAR_PREFILLED_MODAL,
});
