import React from "react";
import { about_us_data } from "../../data/about_us";
import { SectionTitle } from "../HomePage";

const AboutUs: React.FC<{}> = () => {
  return (
    <div className="w-full md-min:h-screen md:px-5 px-20 md-min:pt-24 md:py-24">
      <div className="">
        <SectionTitle>About Us</SectionTitle>
      </div>

      <div className="md:py-5 py-10 textColorDark">
        {about_us_data.map((item, index) => (
          <p className="my-3 text-xl" key={index}>
            {item.paragraph}
          </p>
        ))}

        <p className="mt-10 text-xl">
          If you have any questions or comments, please don't hesitate to
          contact us at{" "}
          <span className="textColorDarkAccent">
            <a href="mailto:info@hostbyt.com">info@hostbyt.com</a>
          </span>
          .
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
