import styled from "styled-components";

export const PolicyHeadings = styled.h1`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 32px;
  text-transform: capitalize;
  color: var(--appColor-dark);
  text-align: center;
`;

export const PolicySubHeadings = styled.h2`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 22px;
  text-transform: capitalize;
  color: var(--appColor-dark);
  text-align: left;
`;

export const SubHeadingTopics = styled.h2`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  color: var(--appColor-dark);
  text-align: left;
`;

export const scroll = (id: string) => {
  const element = document.getElementById(id)!;
  element.scrollIntoView({ behavior: "smooth" });
};

// const func = {};

// export default func;
