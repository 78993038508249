import React, { PropsWithChildren } from "react";
import PropTypes from "prop-types";
import "./footer.css";
import styled from "styled-components";
import {
  contacts_data,
  social_media_data,
} from "../../../data/contact_details";
import {
  footer_links_data,
  payment_options_data,
} from "../../../data/footer_links";
import Button from "../Button";
import { Icon } from "@iconify/react";
import { connect, useDispatch } from "react-redux";
import {
  closeQuoteModal,
  openQuoteModal,
} from "../../../store/Miscellaneous/miscellaneousActionCreator";
import { Link } from "react-router-dom";

export const FooterMainHeading = styled.h2`
  font-family: Nunito, sans-serif;
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
  color: var(--appColor-light);
`;

export const FooterHeadings = styled.h3`
  font-family: Nunito, sans-serif;
  font-weight: 700;
  font-size: 18px;
  text-transform: capitalize;
  color: var(--appColor-light);
`;

interface IProps {
  isQuoteModalOpen?: boolean;
}

const Footer: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { isQuoteModalOpen } = props;

  const dispatch = useDispatch();

  function getYear() {
    return new Date().getFullYear();
  }

  function toggleModal() {
    var homePageDiv = document.getElementById("homepage");

    if (isQuoteModalOpen) {
      dispatch(closeQuoteModal());
      homePageDiv!.classList.remove("modalBackgroundDiv");
    } else {
      dispatch(openQuoteModal());
      homePageDiv!.classList.add("modalBackgroundDiv");
    }
  }

  return (
    <>
      <footer className="w-full bottom-0 appBgGradient largeScreenLayout">
        <div className="nav_footerLargeScreenSize w-full md:px-5 px-10 py-5">
          <div className="w-full py-10 grid md:grid-cols-1 grid-cols-4 md:gap-5 gap-2">
            <div className="w-full flex flex-col">
              <FooterMainHeading>Follow Us</FooterMainHeading>

              <div className="sm:w-full md:w-6/12 w-9/12 py-5 flex flex-row">
                {social_media_data.map((item: any, index: number) => (
                  <a
                    className="mr-4 flex flex-row items-center"
                    href={item.link}
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={item.brandIcon}
                      alt={`${item.platform} icon`}
                      className="my-auto"
                    />
                  </a>
                ))}
              </div>
            </div>

            <div className="flex flex-col">
              <FooterHeadings>Links</FooterHeadings>

              <div className="inline-block textColorLight text-base">
                {footer_links_data.map((item: any, index: number) => (
                  <Link to={item.to} key={index} className="block mb-2 md:my-1">
                    {item.linkName}
                  </Link>
                ))}
              </div>
            </div>

            <div className="">
              <Button
                id="getWebsite"
                className="font-semibold"
                primary
                label="Need a website?"
                padding="0px"
                color="var(--appColor-light)"
                backgroundColor="transparent"
                option="rounded"
                onClick={toggleModal}
              />
            </div>

            <div className="">
              <FooterHeadings>Contact Details</FooterHeadings>

              <div className="flex flex-col textColorLight">
                {contacts_data.map((item: any, index: number) => (
                  <a
                    className="my-2 md:my-1 flex flex-row"
                    href={`${item.action}:${item.value}`}
                    key={index}
                  >
                    <Icon
                      icon={item.icon}
                      className="mr-4"
                      color="var(--appColor-light)"
                      fontSize={25}
                    />
                    <p className="">{item.value}</p>
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="dividerLine appBgColorLight"></div>

          <div className="w-full mt-5 flex md:flex-col-reverse md:items-start md-min:flex-row md-min:justify-between md-min:items-center">
            <div className="md:w-full md:mt-10 md:text-center">
              <p className="text-sm textColorLight">
                &copy; Copyright {getYear()} HostByt Web Services.
              </p>
            </div>

            <div className="flex md:flex-col flex-row md-min:items-center">
              <p className="text-sm textColorLight">Payment Methods:</p>

              <div className="md:mt-5 md-min:ml-2 grid md:grid-cols-3 grid-cols-4 gap-2">
                {payment_options_data.map((item: any, index: number) => (
                  <div className="paymentOptionDiv appBgColorLight" key={index}>
                    <div className="w-full h-full p-2 flex flex-row justify-center items-center">
                      <img
                        src={item.logo}
                        alt="payment option logo"
                        className="paymentOptionLogo"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {
  children: PropTypes.any,
};

function mapStateToProps(state: any) {
  return {
    isQuoteModalOpen: state.miscellaneous.isQuoteModalOpen,
  };
}

export default connect(mapStateToProps)(Footer);
